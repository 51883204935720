export enum Config {
	apiUrl = "https://talent-quest.simplogics.com/api/v1",
	apiSecureUrl = "https://talent-quest.simplogics.com/api/v1/secure",
	defaultLanguage = "en",
	version = "v1.4.0",
	siteUrl = "https://talent-quest.simplogics.com",
}

// export enum Config {
// 	apiUrl = "http://localhost:8080/api/v1",
// 	apiSecureUrl = "http://localhost:8080/api/v1/secure",
// 	defaultLanguage = "en",
// 	version = "v1.3.3",
// 	siteUrl = "http://15.207.0.61",
// }

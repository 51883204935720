import { createAsyncThunk } from "@reduxjs/toolkit";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";
import axios from "axios";
import { ActionChange } from "../../modal/result";

export const getAllResults = createAsyncThunk("results/all", async (query: string) => {
	const response = await axios.get(Config.apiSecureUrl + `/result?${query}`, axiosConfig());
	return response.data;
});

export const actionChange = createAsyncThunk("action/change", async (payload: ActionChange) => {
	const response = await axios.put(Config.apiSecureUrl + `/result/${payload.id}`, payload.payload, axiosConfig());
	return response.data;
});

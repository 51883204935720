import { createAsyncThunk } from "@reduxjs/toolkit";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";
import axios from "axios";
import { QuestionUpdate , QuestionState} from "../../modal/question";

export const getAllQuestions = createAsyncThunk("questions/all", async () => {
	const response = await axios.get(Config.apiSecureUrl + "/questions?offset=0&numResults=1000", axiosConfig());
	return response.data;
});

export const questionCreate = createAsyncThunk("question/create", async (payload: QuestionState) => {
	const response = await axios.post(Config.apiSecureUrl + "/questions", payload, axiosConfig());
	return response.data;
});

export const getOneQuestion = createAsyncThunk("question/getOne", async (questionId: number) => {
	const response = await axios.get(Config.apiSecureUrl + `/questions/${questionId}`,axiosConfig());
	return response.data;
});
export const deleteQuestion = createAsyncThunk("question/delete", async (id: number) => {
	const response = await axios.delete(Config.apiSecureUrl + `/questions?id=${id}`, axiosConfig());
	return response.data;
});
export const questionUpdate = createAsyncThunk("question/update", async (payload: QuestionUpdate, ) => {
	const response = await axios.put(
		Config.apiSecureUrl + `/questions/${payload.questionId}`,
		payload.question,
		axiosConfig()
	);
	return response.data;
});
export const getLink = createAsyncThunk("get/link", async () => {
	const response = await axios.get(Config.apiSecureUrl + "/exam/token", axiosConfig());
	return response.data;
});

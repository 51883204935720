import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { APIStatus } from "../../constants";
import { deleteQuestion, getAllQuestions } from "../../services/questions/questions.service";
import { clearDeleteQuestion, clearGetAllQuestions } from "../../services/questions/questions.slice";
import { QuestionCreate } from "../question-create";
import "./questions.scss";
import { deleteModal } from "../../plugins/delete-modal";
import { QuestionEdit } from "../question-edit";
import { GenerateLink } from "../generate-link";

export const QuestionList: React.FunctionComponent = () => {
	const getAllQuestionsComplete = useAppSelector((store) => store.questions.getAllQuestionsComplete);
	const deleteQuestionComplete = useAppSelector((store) => store.questions.deleteQuestionComplete);
	const [id, setId] = useState<number | null>();
	const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] = useState(false);
	const [isEditQuestionModalOpen, setIsEditQuestionModalOpen] = useState(false);
	const questions = useAppSelector((store) => store.questions.question);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState<boolean>(true);

	const deleteClicked = (id: number) => {
		deleteModal({
			id: id,
			content: "Are you sure you want to delete the question ?",
			delete: () => {
				setLoading(true);
				dispatch(deleteQuestion(id));
			},
		});
	};

	useEffect(() => {
		setLoading(true);
		dispatch(getAllQuestions());
		setLoading(true);
	}, []);

	useEffect(() => {
		if (getAllQuestionsComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearGetAllQuestions());
		}
		if (getAllQuestionsComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGetAllQuestions());
		}
	}, [getAllQuestionsComplete]);

	useEffect(() => {
		if (deleteQuestionComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(getAllQuestions());
			dispatch(clearDeleteQuestion());
		}
		if (deleteQuestionComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearDeleteQuestion());
		}
	}, [deleteQuestionComplete]);

	const onAddQuestionClick = () => {
		setIsCreateQuestionModalOpen(true);
	};

	const questionEditClicked = (id: number | null) => {
		setIsEditQuestionModalOpen(true);
		setId(id);
	};

	return (
		<div className="overview_content_box questions_listing_main_container d-flex flex-column pt-0">
			<div className="button_container pr-2">
				<GenerateLink />
				<button className="primary_border_btn min_height_36 font_16 min_width_175 ml-2" onClick={onAddQuestionClick}>
					Add New Question
				</button>
			</div>
			<div className="questions_listing_content_container flex_1_1_10_limit">
				{loading ? (
					<div className="d-flex align-items-center justify-content-center h-100">Loading...</div>
				) : (
					<>
						{questions.length === 0 ? (
							<div className="d-flex align-items-center justify-content-center h-100">No Data</div>
						) : (
							<>
								{questions.map((question, index) => (
									<div key={question.id} className="questions_listing_box">
										<div className="d-flex justify-content-between">
											<div className="question_container w-100">
												<div className="d-flex mb-2">
													<div className="mr-1">{index + 1}.</div>
													<div className="word_break">{question.question}</div>
												</div>
												{question.attachment ? (
													<div className="attachment_container mt-2 mb-3">
														<img src={question.attachment} />
													</div>
												) : (
													""
												)}
											</div>
											<div className="qn_edit_delete_container min_width_65 text-right">
												<span className="pr-1" onClick={() => questionEditClicked(question.id)}>
													<svg
														className="delete_icon cursor_pointer"
														width="27"
														height="27"
														viewBox="0 0 27 27"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0 7.26973C0.152968 6.85564 0.250311 6.40858 0.470234 6.03363C1.08931 4.97573 2.04678 4.41331 3.27103 4.40506C6.18926 4.38446 9.10749 4.39631 12.0257 4.39991C12.704 4.40094 13.1928 4.88096 13.1846 5.51601C13.1763 6.13921 12.6891 6.59863 12.0201 6.59914C9.16209 6.6012 6.3036 6.60018 3.44563 6.60018C2.63393 6.60018 2.20284 7.02508 2.20284 7.83267C2.20181 12.8713 2.20181 17.91 2.20284 22.9486C2.20284 23.7475 2.62723 24.1765 3.42246 24.177C8.46935 24.1786 13.5157 24.1786 18.5626 24.177C19.3481 24.177 19.7776 23.7356 19.7781 22.9404C19.7787 20.0737 19.7766 17.2064 19.7797 14.3397C19.7807 13.5357 20.4992 13.0037 21.2331 13.2519C21.6951 13.4085 21.9763 13.8211 21.9789 14.3701C21.983 15.3574 21.9805 16.3443 21.9805 17.3316C21.9805 19.16 21.9805 20.9884 21.9805 22.8168C21.9805 24.6241 21.1379 25.7644 19.4083 26.2995C19.3929 26.3041 19.3805 26.3186 19.3666 26.3289C13.7697 26.3289 8.17321 26.3289 2.57624 26.3289C2.51547 26.3036 2.45572 26.2722 2.39289 26.2532C1.52298 25.99 0.845184 25.4724 0.42285 24.6699C0.230739 24.3053 0.138546 23.8886 0.00103011 23.4956C2.35998e-08 18.0866 0 12.6782 0 7.26973Z"
															fill="#454849"
														/>
														<path
															d="M26.3702 3.09734C26.2285 3.97652 25.7027 4.61466 25.0723 5.19459C24.7256 5.51289 24.4053 5.85951 24.0968 6.16957C22.802 4.87475 21.519 3.59127 20.2139 2.28615C20.2417 2.25731 20.3086 2.18417 20.3787 2.11413C20.7969 1.69488 21.2125 1.27358 21.6349 0.858968C22.4239 0.0853742 23.3634 -0.190688 24.4207 0.133273C25.4755 0.45672 26.1101 1.1989 26.3269 2.28821C26.3331 2.31911 26.3552 2.34744 26.3702 2.3768C26.3702 2.61681 26.3702 2.85682 26.3702 3.09734Z"
															fill="#454849"
														/>
														<path
															d="M18.972 3.53156C20.2689 4.82843 21.5534 6.11295 22.8472 7.40725C22.8045 7.45257 22.7488 7.51489 22.6891 7.57464C19.9037 10.361 17.1199 13.1489 14.3263 15.9271C14.1672 16.0857 13.9339 16.2134 13.715 16.2608C12.517 16.5199 11.3133 16.7496 10.1107 16.9875C9.61422 17.0859 9.29026 16.7774 9.38658 16.2881C9.63431 15.035 9.88565 13.783 10.1463 12.5329C10.1741 12.3985 10.2482 12.2533 10.3446 12.1565C13.2046 9.28715 16.0708 6.42352 18.9355 3.55885C18.9478 3.54649 18.9633 3.53774 18.972 3.53156Z"
															fill="#454849"
														/>
													</svg>
												</span>
												<span
													className={loading ? "" : ""}
													onClick={() => {
														!loading ? deleteClicked(question.id) : "";
													}}
												>
													<svg
														className="edit_icon cursor_pointer ml-1"
														width="21"
														height="26"
														viewBox="0 0 21 26"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M1.12717 14.6638C1.12717 12.5788 1.12576 10.4945 1.12788 8.40942C1.12859 7.74745 1.48612 7.29433 2.07588 7.19097C2.72794 7.07627 3.34035 7.55487 3.3885 8.21968C3.39558 8.31667 3.39204 8.41367 3.39204 8.51137C3.39204 12.5837 3.39204 16.6561 3.39204 20.7285C3.39204 22.0829 4.31455 23.004 5.67177 23.004C8.67507 23.004 11.6777 23.004 14.681 23.004C16.0375 23.004 16.9607 22.0815 16.9607 20.7278C16.9607 16.62 16.9607 12.5122 16.9614 8.40447C16.9614 7.76869 17.302 7.31345 17.8584 7.19946C18.4978 7.06778 19.0925 7.47345 19.2015 8.12056C19.222 8.24163 19.2263 8.36623 19.2263 8.48942C19.2277 12.5795 19.2284 16.6696 19.227 20.7596C19.2263 23.0011 17.7275 24.803 15.5249 25.203C15.2658 25.2504 14.9982 25.2667 14.7341 25.2674C11.6954 25.2717 8.65737 25.2724 5.61867 25.2695C3.09397 25.2674 1.13 23.3063 1.12646 20.7858C1.12505 18.7454 1.12717 16.705 1.12717 14.6638Z"
															fill="#E23813"
														/>
														<path
															d="M14.6958 3.7735C14.9918 3.7735 15.2467 3.7735 15.5022 3.7735C16.7207 3.7735 17.9391 3.76784 19.1576 3.77563C19.9838 3.78129 20.5276 4.48291 20.3031 5.23338C20.17 5.678 19.7792 5.9966 19.3169 6.03554C19.2553 6.04049 19.1937 6.03978 19.1314 6.03978C13.1623 6.03978 7.19325 6.04049 1.22346 6.03908C0.504138 6.03908 -0.0056172 5.55977 4.67316e-05 4.89709C0.00571067 4.24715 0.510509 3.77704 1.21992 3.77421C2.57997 3.76996 3.93932 3.77279 5.29937 3.77279C5.40415 3.77279 5.50964 3.77279 5.65761 3.77279C5.65761 3.66022 5.65761 3.56748 5.65761 3.47473C5.65761 2.83895 5.65336 2.20318 5.65903 1.5674C5.6654 0.856575 6.12559 0.384344 6.83713 0.38222C9.06235 0.375848 11.2876 0.375848 13.5128 0.38222C14.2257 0.384344 14.6873 0.853743 14.693 1.56457C14.7008 2.28813 14.6958 3.01029 14.6958 3.7735ZM7.92885 3.75085C9.43829 3.75085 10.9307 3.75085 12.4225 3.75085C12.4225 3.37207 12.4225 3.01595 12.4225 2.66195C10.9137 2.66195 9.42696 2.66195 7.92885 2.66195C7.92885 3.03365 7.92885 3.38977 7.92885 3.75085Z"
															fill="#E23813"
														/>
														<path
															d="M6.78622 14.4946C6.78622 13.1792 6.78127 11.8637 6.78905 10.5483C6.79189 9.99603 7.17987 9.55283 7.71936 9.44876C8.20929 9.35459 8.73674 9.61938 8.93569 10.0902C9.00932 10.2637 9.04613 10.4654 9.04684 10.6552C9.05392 13.233 9.05392 15.8115 9.05038 18.3893C9.04967 19.11 8.5647 19.6176 7.90273 19.6106C7.25279 19.6035 6.78764 19.0973 6.78622 18.3886C6.7841 17.0908 6.78551 15.7931 6.78622 14.4946Z"
															fill="#E23813"
														/>
														<path
															d="M13.57 14.5279C13.57 15.8434 13.5735 17.1588 13.5678 18.4743C13.5657 19.0088 13.2351 19.4286 12.7331 19.5674C12.2496 19.7012 11.72 19.5065 11.4821 19.0626C11.378 18.8686 11.3129 18.6286 11.3122 18.4091C11.3008 15.8136 11.3016 13.2174 11.3072 10.6219C11.3086 9.92314 11.8056 9.42401 12.4591 9.43392C13.0991 9.44313 13.5685 9.94651 13.57 10.6347C13.5721 11.9324 13.57 13.2302 13.57 14.5279Z"
															fill="#E23813"
														/>
													</svg>
												</span>
											</div>
										</div>
										<div className="answer_content_container">
											{question.options.map((option, index) => (
												<div
													key={index}
													className={option.isCorrect ? "answer_box correct_answer mr-3" : "answer_box mr-3"}
												>
													<div className="d-flex">
														<div className="mr-1">{index + 1}.</div>
														<div className="word_break">{option.text}</div>
													</div>
													{option.attachment ? (
														<div className="attachment_container py-2">
															<img src={option.attachment} />
														</div>
													) : (
														""
													)}
												</div>
											))}
										</div>
									</div>
								))}
							</>
						)}
					</>
				)}

				{isCreateQuestionModalOpen ? (
					<QuestionCreate loading={loading} setIsCreateQuestionModalOpen={setIsCreateQuestionModalOpen} />
				) : (
					""
				)}
				{isEditQuestionModalOpen ? (
					<QuestionEdit setIsQuestionModalClose={setIsEditQuestionModalOpen} questionId={id ? id : null} />
				) : (
					""
				)}
			</div>
		</div>
	);
};

import React, { useEffect } from "react";
import "./end-page.scss";

export const EndPage: React.FunctionComponent = () => {
	useEffect(() => {
		localStorage.removeItem("isBackNavigate");
		localStorage.removeItem("examActive");
		localStorage.removeItem("studentsDetails");
	}, []);
	return (
		<div className="student_module_overview_bg_container main_outer_bg">
			<div className="student_module_overview_content_box overview_inner_bg">
				<div className="overview_content_box_content d-flex flex-column align-items-center justify-content-center h-100">
					<div className="heading_text mb-2">Thank You</div>
					<div className="sub_text">You have completed the examination.</div>
					<div className="sub_text mb-3">We will get back to you soon.</div>
					<div className="logo_container">
						<img className="logo_img" src="/images/logo.png" />
					</div>
					<div className="sub_text font_15">www.simplogics.com</div>
				</div>
			</div>
		</div>
	);
};

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getAllResults } from "../../services/results/results.service";
import { clearActionChange, clearAllResults } from "../../services/results/results.slice";
import { DepartmentDropDown } from "../../components/department-dropDown";
import { GenderDropDown } from "../../components/gender";
import { Filter } from "../../modal/result";
import { columns } from "./columns";
import { Table } from "../../plugins/table";
import { PaginationWithNumber } from "../../modal/common";
import { resetPage } from "../../services/table-plugin/table-plugin.slice";
import "./results.scss";
import { SearchIconSvg } from "../../components/search-icon";
import axios from "axios";
import { Config } from "../../config";
import { APIStatus, SessionStorageKeys } from "../../constants";

export const ResultList: React.FunctionComponent = () => {
	const results = useAppSelector((store) => store.results);
	const studentStatusUpdateCompleted = useAppSelector((store) => store.results.actionChangeCompleted);
	const dispatch = useAppDispatch();
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
	const [filter, setFilter] = useState<Filter>({
		department: null,
		gender: null,
		search: "",
	});
	const [downloadLoading, setDownloadLoading] = useState(false);
	const resultLoading = useAppSelector((store) => store.results.resultLoading);
	const submittedCount = useAppSelector((store) => store.results.totalCount);

	useEffect(() => {
		if (!isFirstLoad) {
			dispatch(resetPage());
		}
	}, [filter.search, filter.department, filter.gender]);

	useEffect(() => {
		if (studentStatusUpdateCompleted === APIStatus.FULFILLED) {
			dispatch(clearActionChange());
			dispatch(resetPage());
		}
		if (studentStatusUpdateCompleted === APIStatus.REJECTED) {
			dispatch(clearActionChange());
		}
	}, [studentStatusUpdateCompleted]);

	const clearAllFilters = () => {
		if (JSON.stringify(filter) !== JSON.stringify({})) {
			setFilter({
				department: null,
				gender: null,
				search: "",
			});
		}
	};

	const downloadClicked = () => {
		let query = `${filter.department ? `&department=${filter.department}` : ""}`;
		query += `${filter.gender ? `&gender=${filter.gender}` : ""}`;
		query += `${filter.search ? `&search=${filter.search}` : ""}`;
		setDownloadLoading(true);
		axios({
			url: `${Config.apiSecureUrl}/result/excel?${query}`,
			method: "GET",
			responseType: "blob",
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem(SessionStorageKeys.TOKEN)}`,
			},
		})
			.then((response) => {
				const blob = new Blob([response.data], { type: "text/xls" });
				const windowUrl = window.URL || window.webkitURL;
				const url = windowUrl.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = "exam_result.xlsx";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setDownloadLoading(false);
			})
			.catch(() => {
				setDownloadLoading(false);
			});
	};

	const searchChanged = (search: string) => {
		setFilter((prevState) => ({ ...prevState, search: search }));
	};

	return (
		<div className="overview_content_box result_listing_main_container d-flex flex-column">
			<div className="overview_tool_box custom_main_form filter_container d-flex align-items-center justify-content-end">
				<div className="custom_input_field_container max_width_210 flex_1_1_10 position-relative">
					<input
						type="text"
						className="custom_input_field form_input padding_right_30"
						placeholder="Search"
						onChange={(e) => searchChanged(e.target.value)}
						value={filter.search}
					/>
					<SearchIconSvg className="search_icon_svg cursor_pointer" />
				</div>
				<div className="custom_input_field_container max_width_210 flex_1_1_10 ml-2">
					<DepartmentDropDown
						clearable
						handleChange={(e: string) => {
							setFilter({ ...filter, department: e });
						}}
						value={filter.department}
					/>
				</div>
				<div className="custom_input_field_container max_width_210 flex_1_1_10 ml-2">
					<GenderDropDown
						clearable
						handleChange={(e: string) => {
							setFilter({ ...filter, gender: e });
						}}
						value={filter.gender}
					/>
				</div>
				<div className="clear_all font_16 cursor_pointer ml-3" onClick={clearAllFilters}>
					Clear all
				</div>
				<button
					className="primary_border_btn min_width_175 min_height_36 font_16 ml-4"
					disabled={downloadLoading || results.result.length === 0}
					onClick={downloadClicked}
				>
					Export Excel
				</button>
			</div>
			<div className="custom_table_main_container">
				<Table
					loading={resultLoading}
					totalResults={submittedCount}
					defaultSort=""
					headers={columns()}
					tableData={results.result}
					fetchData={(pagination: PaginationWithNumber) => {
						setIsFirstLoad(false);
						if (pagination.pageNumber === 0) {
							dispatch(clearAllResults());
						}
						let query = `offset=${pagination.pageNumber}&numResults=${pagination.numResults}`;
						query += `${filter.department ? `&department=${filter.department}` : ""}`;
						query += `${filter.gender ? `&gender=${filter.gender}` : ""}`;
						query += `${filter.search ? `&search=${filter.search}` : ""}`;
						query += `${pagination.sort ? `&sortBy=${pagination.sort}` : "&sortBy=score"}`;
						query += `${pagination.sort ? `&desc=${pagination.desc}` : ""}`;
						dispatch(getAllResults(query));
					}}
				/>
			</div>
		</div>
	);
};

import React from "react";
import { Status } from "../../constants";
import { Select } from "antd";
import { Options } from "../../modal/registration";
import { DropDownIcon } from "../drop-down-icon";

type StatusDropDownProps = {
	value: string;
	handleChange(e: string): void;
};

export const StatusDropDown: React.FunctionComponent<StatusDropDownProps> = (props) => {
	const Option = Select;

	return (
		<>
			{props.value === "FAILED" ? (
				<div>Failed</div>
			) : (
				<Select
					className="data_selector form_input"
					optionFilterProp={"children"}
					value={props.value ? props.value : Status[0]?.value}
					onChange={(value) => props.handleChange(value)}
					suffixIcon={<DropDownIcon />}
				>
					{Status.map((el: Options, i: number) => {
						return (
							<Option key={`user_role_${i}`} value={el.value}>
								{el.label}
							</Option>
						);
					})}
				</Select>
			)}
		</>
	);
};

import React, { forwardRef, useState, useImperativeHandle } from "react";
import {
	cgpaRegex,
	emailRegex,
	mustHaveLetter,
	numberRegex,
	phoneNumberRegex,
	positiveNumbers,
	whiteSpace,
} from "../../constants";
import { Invalid, StudentsRegistrationPayload, StudentsRegistrationState } from "../../modal/registration";
import { DepartmentDropDown } from "../department-dropDown";
import { GenderDropDown } from "../gender";
import "./registration.scss";

export type RegistrationFormHandles = {
	isValid(): boolean;
	get(): StudentsRegistrationPayload;
};

type StudentRegistrationProps = {
	disabled: boolean;
};

const StudentRegistrationForm: React.ForwardRefRenderFunction<RegistrationFormHandles, StudentRegistrationProps> = (
	props,
	ref
) => {
	const [studentsData, setStudentsData] = useState<StudentsRegistrationState>({
		name: "",
		email: "",
		place: "",
		collegeCode: "",
		cgpa: "",
		gender: "",
		contactNumber: "",
		department: "",
		backPapers: "",
		collegeName: "",
	});

	const [invalid, setInvalid] = useState<Invalid>({
		name: false,
		email: false,
		place: false,
		collegeId: false,
		collegeName: false,
		cgpa: false,
		backPapers: false,
		gender: false,
		contactNumber: false,
		department: false,
	});

	useImperativeHandle(ref, () => ({
		isValid: (): boolean => {
			const prevState: Invalid = JSON.parse(JSON.stringify(invalid));
			let valid = true;
			if (!studentsData.name || !mustHaveLetter.test(studentsData.name)) {
				prevState.name = true;
				valid = false;
			}
			if (!studentsData.email || !emailRegex.test(studentsData.email)) {
				prevState.email = true;
				valid = false;
			}
			if (!studentsData.place || whiteSpace.test(studentsData.place)) {
				prevState.place = true;
				valid = false;
			}
			if (!studentsData.collegeCode || whiteSpace.test(studentsData.collegeCode)) {
				prevState.collegeId = true;
				valid = false;
			}
			if (studentsData.backPapers && !positiveNumbers.test(studentsData.backPapers)) {
				prevState.backPapers = true;
				valid = false;
			}
			if (!studentsData.collegeName || whiteSpace.test(studentsData.collegeName)) {
				prevState.collegeName = true;
				valid = false;
			}
			if (!studentsData.cgpa || !cgpaRegex.test(studentsData.cgpa)) {
				prevState.cgpa = true;
				valid = false;
			}
			if (!studentsData.gender) {
				prevState.gender = true;
				valid = false;
			}
			if (!studentsData.contactNumber || !phoneNumberRegex.test(studentsData.contactNumber)) {
				prevState.contactNumber = true;
				valid = false;
			}
			if (!studentsData.department) {
				prevState.department = true;
				valid = false;
			}
			setInvalid(prevState);
			return valid;
		},
		get: (): StudentsRegistrationPayload => {
			return studentsData;
		},
	}));

	return (
		<div className="registration_main_form flex_1_1_10">
			<div className="registration_header_text">Registration</div>
			<div className="d-flex custom_main_form overflow-auto">
				<div className="w-50 pr-3">
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Full Name</div>
						<div className="custom_input_field_container position-relative">
							<input
								className="custom_input_field form_input"
								type="text"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.name}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, name: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, name: false }));
								}}
							/>
							{invalid.name ? <span className="invalid_message invalid_top_minus_16">Enter your name</span> : ""}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Email</div>
						<div className="custom_input_field_container position-relative">
							<input
								className="custom_input_field form_input"
								type="text"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.email}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, email: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, email: false }));
								}}
							/>
							{invalid.email ? <span className="invalid_message invalid_top_minus_16">Enter your email</span> : ""}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Place</div>
						<div className="custom_input_field_container position-relative">
							<input
								className="custom_input_field form_input"
								type="text"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.place}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, place: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, place: false }));
								}}
							/>
							{invalid.place ? <span className="invalid_message invalid_top_minus_16">Enter your place</span> : ""}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">College ID</div>
						<div className="custom_input_field_container position-relative">
							<input
								type="text"
								className="custom_input_field form_input"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.collegeCode}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, collegeCode: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, collegeId: false }));
								}}
							/>
							{invalid.collegeId ? (
								<span className="invalid_message invalid_top_minus_16">Enter your College ID</span>
							) : (
								""
							)}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">CGPA</div>
						<div className="custom_input_field_container position-relative">
							<input
								className="custom_input_field form_input"
								type="text"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.cgpa}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, cgpa: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, cgpa: false }));
								}}
							/>
							{invalid.cgpa ? <span className="invalid_message invalid_top_minus_16">Enter your CGPA</span> : ""}
						</div>
					</div>
				</div>
				<div className="w-50 pl-3">
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Gender</div>
						<div className="custom_input_field_container position-relative">
							<GenderDropDown
								value={studentsData.gender}
								handleChange={(e: string) => {
									setStudentsData((prevState) => ({ ...prevState, gender: e }));
									setInvalid((prevState) => ({ ...prevState, gender: false }));
								}}
							/>
							{invalid.gender ? <span className="invalid_message invalid_top_minus_16">Select your gender</span> : ""}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Contact Number</div>
						<div className="custom_input_field_container position-relative">
							<input
								className="custom_input_field form_input"
								type="number"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.contactNumber}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, contactNumber: e.target.value.slice(0, 20) }));
									setInvalid((prevState) => ({ ...prevState, contactNumber: false }));
								}}
							/>
							{invalid.contactNumber ? (
								<span className="invalid_message invalid_top_minus_16">Enter your contact number</span>
							) : (
								""
							)}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">College Name</div>
						<div className="custom_input_field_container position-relative">
							<input
								type="text"
								className="custom_input_field form_input"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.collegeName}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, collegeName: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, collegeName: false }));
								}}
							/>
							{invalid.collegeName ? (
								<span className="invalid_message invalid_top_minus_16">Enter your college name</span>
							) : (
								""
							)}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Department</div>
						<div className="custom_input_field_container position-relative">
							<DepartmentDropDown
								value={studentsData.department}
								handleChange={(e: string) => {
									setStudentsData((prevState) => ({ ...prevState, department: e }));
									setInvalid((prevState) => ({ ...prevState, department: false }));
								}}
							/>
							{invalid.department ? (
								<span className="invalid_message invalid_top_minus_16">Select your department</span>
							) : (
								""
							)}
						</div>
					</div>
					<div className="mb-3">
						<div className="custom_input_label register_form_label">Back Papers (if any)</div>
						<div className="custom_input_field_container position-relative">
							<input
								className="custom_input_field form_input"
								type="number"
								disabled={props.disabled}
								inputMode="text"
								value={studentsData.backPapers}
								onChange={(e) => {
									setStudentsData((prevState) => ({ ...prevState, backPapers: e.target.value }));
									setInvalid((prevState) => ({ ...prevState, backPapers: false }));
								}}
							/>
							{invalid.backPapers ? (
								<span className="invalid_message invalid_top_minus_16">Enter a valid back paper value</span>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default forwardRef(StudentRegistrationForm);

import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getLink } from "../../services/questions/questions.service";
import "./generate-link.scss";
import { APIStatus } from "../../constants";
import { clearGetLink } from "../../services/questions/questions.slice";
import { Config } from "../../config";

export const GenerateLink: React.FunctionComponent = () => {
	const getLinkComplete = useAppSelector((store) => store.questions.getLinkComplete);
	const token = useAppSelector((store) => store.questions.token);
	const dispatch = useAppDispatch();
	const [isGenerateLinkModalOpen, setIsGenerateLinkModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const link = `${Config.siteUrl}/${token}/registration`;

	const handleCopyLink = () => {
		if (window.navigator.clipboard) {
			window.navigator.clipboard.writeText(link);
			message.success("Link copied to clipboard");
		}
	};

	const handleCancel = () => {
		setIsGenerateLinkModalOpen(false);
	};
	const onGenerateLinkClick = () => {
		setLoading(true);
		dispatch(getLink());
	};

	useEffect(() => {
		if (getLinkComplete === APIStatus.FULFILLED) {
			setLoading(false);
			setIsGenerateLinkModalOpen(true);
			dispatch(clearGetLink());
		}
		if (getLinkComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGetLink());
		}
	}, [getLinkComplete]);

	return (
		<>
			<button
				className="primary_border_btn min_height_36 font_16 min_width_175"
				disabled={loading}
				onClick={onGenerateLinkClick}
			>
				Generate Link
			</button>
			{isGenerateLinkModalOpen ? (
				<Modal
					title="Examination Link"
					visible={true}
					className="generate_link_pop_up_screen"
					onCancel={handleCancel}
					closable={true}
					footer={null}
				>
					<div className="generate_link_box_content mb-2">
						<div className="generate_link_sub_header mb-2">You may share the link to initiate the exam.</div>
						<div className="d-flex align-items-center w-100">
							<div className="generate_link text-break">{link}</div>
							<div className="button_container">
								<button className="primary_border_btn font_16 min_width_120 ml-2" onClick={handleCopyLink}>
									Copy
								</button>
							</div>
						</div>
					</div>
				</Modal>
			) : (
				""
			)}
		</>
	);
};

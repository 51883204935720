import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";
import { Exam } from "../../modal/exam";

export type answers = {
	questionId: number,
	selectedOptionId: number | null;
};
export type examPayload = {
	studentId: number,
    examToken: string ,
    questions: answers[]
};

export type failedExamPayload = {
	id: number;
	token: tokenPayload;
}

export type tokenPayload = {
	token: string;
}

export const startExam = createAsyncThunk("start/exam", async (payload: Exam) => {
	const response = await axios.post(
		Config.apiUrl + "/exam/time",payload,
		axiosConfig()
	);
	return response.data;
});

export const getAllQuestions = createAsyncThunk("questions/all", async () => {
	const response = await axios.get(Config.apiUrl + "/exam?offset=0&numResults=1000", axiosConfig());
	return response.data;
});

export const submitExam = createAsyncThunk("exam/submit", async (payload: examPayload) => {
	const response = await axios.post(Config.apiUrl + "/exam/submit", payload, axiosConfig());
	return response.data;
});

export const failedExam = createAsyncThunk("failed/exam", async (payload: failedExamPayload) => {
	const response = await axios.put(Config.apiUrl + `/exam/select/${payload.id}`, payload.token, axiosConfig());
	return response.data;
});
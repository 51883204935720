import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { questionCreate, getAllQuestions, getOneQuestion , deleteQuestion, questionUpdate, getLink} from "./questions.service";
import { QuestionReducer, QuestionState} from "../../modal/question";

const initialState: QuestionReducer = {
	getAllQuestionsComplete: null,
	updateQuestionDetailsCompleted: null,
	createQuestionComplete: null,
	getOneQuestionCompleted: null,
	question: [],
	questionDetails: {} as QuestionState,
	deleteQuestionComplete: null,
	token: "",
	getLinkComplete: null,
};

const questionsSlice = createSlice({
	name: "questions",
	initialState,
	reducers: {
		clearGetAllQuestions(state) {
			state.getAllQuestionsComplete = null;
		},
		clearCreateQuestion(state) {
			state.createQuestionComplete = null;
		},
		clearGetOneQuestion(state) {
			state.getOneQuestionCompleted = null;
		},
		clearDeleteQuestion(state) {
			state.deleteQuestionComplete = null;
		},
		clearUpdateQuestion(state) {
			state.updateQuestionDetailsCompleted = null;
		},
		clearGetLink(state) {
			state.getLinkComplete = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getAllQuestions.fulfilled, (state, action) => {
			state.getAllQuestionsComplete = APIStatus.FULFILLED;
			state.question = action.payload.data;
		});
		builder.addCase(getAllQuestions.rejected, (state) => {
			state.getAllQuestionsComplete = APIStatus.REJECTED;
		});
		builder.addCase(questionCreate.fulfilled, (state) => {
			state.createQuestionComplete = APIStatus.FULFILLED;
		});
		builder.addCase(questionCreate.rejected, (state) => {
			state.createQuestionComplete = APIStatus.REJECTED;
		});
		builder.addCase(getOneQuestion.fulfilled, (state, action) => {
			state.getOneQuestionCompleted = APIStatus.FULFILLED;
			state.questionDetails = action.payload.data;
		});
		builder.addCase(getOneQuestion.rejected, (state) => {
			state.getOneQuestionCompleted = APIStatus.REJECTED;
		});
		builder.addCase(questionUpdate.fulfilled, (state) => {
			state.updateQuestionDetailsCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(questionUpdate.rejected, (state) => {
			state.updateQuestionDetailsCompleted = APIStatus.REJECTED;
		});
		builder.addCase(deleteQuestion.fulfilled, (state) => {
			state.deleteQuestionComplete = APIStatus.FULFILLED;
		});
		builder.addCase(deleteQuestion.rejected, (state) => {
			state.deleteQuestionComplete = APIStatus.REJECTED;
		});
		builder.addCase(getLink.fulfilled, (state, action) => {
			console.log(action.payload.data);
			state.getLinkComplete = APIStatus.FULFILLED;
			state.token = action.payload.data.token;
		});
		builder.addCase(getLink.rejected, (state) => {
			state.getLinkComplete = APIStatus.REJECTED;
		});	
	},
});

export const {
	clearGetAllQuestions,
	clearCreateQuestion,
	clearDeleteQuestion,
	clearGetOneQuestion,
	clearUpdateQuestion,
	clearGetLink,
} = questionsSlice.actions;

export default questionsSlice.reducer;

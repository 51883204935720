import React from "react";

export const DropDownIcon = () => {
	return (
		<>
			<svg
				className="cursor_pointer"
				xmlns="http://www.w3.org/2000/svg"
				width="14"
				height="9"
				viewBox="0 0 14 9"
				fill="none"
			>
				<path d="M7 9L0.937823 0.749999L13.0622 0.75L7 9Z" fill="#787C7E" />
			</svg>
		</>
	);
};

import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { actionChange, getAllResults } from "./results.service";
import { ResultReducer } from "../../modal/result";
import _ from "lodash";

const initialState: ResultReducer = {
	getAllResultsComplete: null,
	result: [],
	resultLoading: false,
	totalCount: 0,
	selectedCount:0,
	submittedCount:0,
	columns: [],
	actionChangeCompleted: null,
};

const resultsSlice = createSlice({
	name: "results",
	initialState,
	reducers: {
		clearGetAllResults(state) {
			state.getAllResultsComplete = null;
		},
		clearAllResults(state) {
			state.result = [];
		},
		clearActionChange(state) {
			state.actionChangeCompleted = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getAllResults.pending, (state) => {
			state.resultLoading = true;
		});
		builder.addCase(getAllResults.fulfilled, (state, action) => {
			state.getAllResultsComplete = APIStatus.FULFILLED;
			state.resultLoading = false;
			state.result = _.unionBy(state.result, action.payload.data.data, "id");
			state.submittedCount =  action.payload.data.submittedCount;
			state.totalCount = action.payload.data.totalCount;
			state.selectedCount = action.payload.data.selectedCount;
		});
		builder.addCase(getAllResults.rejected, (state) => {
			state.getAllResultsComplete = APIStatus.REJECTED;
			state.resultLoading = false;
		});
		builder.addCase(actionChange.fulfilled, (state) => {
			state.actionChangeCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(actionChange.rejected, (state) => {
			state.actionChangeCompleted = APIStatus.REJECTED;
		});
	},
});

export const { clearGetAllResults, clearAllResults, clearActionChange } = resultsSlice.actions;

export default resultsSlice.reducer;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { startExam } from "../../services/exam/exam.service";
import { APIStatus } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { clearStartExamCompleted } from "../../services/exam/exam.slice";
import { createLocation } from "../../utils/util.fns";
import "./instructions.scss";

export const Instructions: React.FunctionComponent = () => {
	const startExamCompleted = useAppSelector((store) => store.exam.startExamCompleted);
	const { token } = useParams();
	const dispatch = useAppDispatch();
	const [isChecked, setIsChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		localStorage.removeItem("examActive");
	}, []);

	useEffect(() => {
		if (!token) {
			navigate(Locations.FAIL_PAGE);
		}
	}, [token]);

	useEffect(() => {
		if (startExamCompleted === APIStatus.FULFILLED) {
			setLoading(false);
			navigate(createLocation(Locations.EXAM, { token: token ?? "" }));
			dispatch(clearStartExamCompleted());
		}
		if (startExamCompleted === APIStatus.REJECTED) {
			setLoading(false);
			navigate(Locations.FAIL_PAGE);
			dispatch(clearStartExamCompleted());
		}
	}, [startExamCompleted]);

	const handleStartExam = () => {
		dispatch(startExam({ token: token ? token : "" }));
	};

	return (
		<div className="student_module_overview_main_container main_outer_bg d-flex flex-column h-100">
			<div className="logo_container">
				<img className="logo_img mr-2" src="/images/logo.png" />
			</div>
			<div className="student_module_overview_content_box flex_1_1_10 p-4">
				<div className="student_module_overview_content_box_content">
					<div className="heading_text mb-2">Instructions</div>
					<div className="instructions_box d-flex flex-column">
						<div className="instructions_box_content overview_outer_border_box flex_1_1_10 overflow-auto">
							<div className="pb-3 sub_header pt-4">Read the instructions carefully before starting the exam</div>
							<div>
								<ul className="px-4">
									<li className="instruction">
										The examination is scheduled for 30 minutes. The test will conclude automatically, and all marked
										answers will be submitted, once the allocated time elapses.
									</li>
									<li className="instruction">The examination comprises a total of 40 questions.</li>
									<li className="instruction">Each answer will be autosaved upon marking.</li>
									<li className="instruction">After completing all questions, kindly submit your test.</li>
									<li className="instruction">You are permitted to revisit and modify answers after marking.</li>
									<li className="instruction">No malpractices are tolerated.</li>
									<li className="instruction">Calculators are not allowed.</li>
								</ul>
							</div>
						</div>
						<div
							className="button_container d-flex align-items-center justify-content-between mb-4"
							key={"sprint-footer"}
						>
							<div className="custom_main_form d-flex align-items-center">
								<input
									className="custom_checkbox cursor_pointer mr-3"
									type="checkbox"
									checked={isChecked}
									onChange={() => setIsChecked(!isChecked)}
								/>
								I hereby declare that I have read the instructions carefully
							</div>
							<button
								className="primary_btn font_16 min_height_36 min_width_130"
								onClick={handleStartExam}
								disabled={!isChecked}
							>
								Start Exam
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
